<template>
  <v-sheet class="fluid-window back-img">
    <v-row justify="center" align="center" class="content-top-padding">
      <v-col cols="4" align-self="center">
        <v-img :src="$api.img_link(client.img)" width="260" height="260" class="logo-img"></v-img>
      </v-col>
      <v-col cols="6" align-self="center">
        <v-card dark class="transparent">
          <v-card-title>
            <span>{{client.name}}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <i18n path="card" />
              </v-col>
              <v-col cols="8">{{client.card}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <i18n path="email" />
              </v-col>
              <v-col cols="8">{{client.email}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <i18n path="phonenum" />
              </v-col>
              <v-col cols="8">{{client.phone}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <i18n path="created" />
              </v-col>
              <v-col cols="8">{{client.audit.created}}</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- <div class="center-block">Contact</div> -->
  </v-sheet>
</template>
<script>
export default {
  name: "Cabinet",
  data() {
    return {};
  },
  computed: {
    client() {
      return this.$store.getters["session/client"];
    },
  },
  methods: {},
};
</script>
<style scoped>
.back-img {
  background-image: url("/img/about.jpg");
  background-position: center;
  background-size: cover;
}
.logo-img {
  margin: auto;
}
</style>
